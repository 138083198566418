import React, { useState, useEffect, forwardRef, useContext, useRef, useCallback } from 'react';
import { MyContext } from '../../studio/studio';
import _ from 'lodash';

const SimpleWysiwyg = forwardRef(({ value: propValue, updateElement, ...props }, ref) => {
    const { formatting, setFormatting } = useContext(MyContext);
    const previousFormatting = useRef({
        bold: false,
        italic: false,
        underline: false,
        orderedList: false,
        unorderedList: false,
    });

    const getAncestor = useCallback((node, tag) => {
        while (node) {
            if (node.tagName && node.tagName.toLowerCase() === tag) {
                return node;
            }
            node = node.parentNode;
        }
        return null;
    }, []);

    const handleListFormatting = useCallback((range, tag) => {
        const oppositeTag = tag === 'ol' ? 'ul' : 'ol';
        const oppositeListAncestor = getAncestor(range.startContainer, oppositeTag);

        if (oppositeListAncestor) {
            const newList = document.createElement(tag);
            while (oppositeListAncestor.firstChild) {
                const listItem = oppositeListAncestor.firstChild;
                const newListItem = document.createElement('li');
                while (listItem.firstChild) {
                    newListItem.appendChild(listItem.firstChild);
                }
                newList.appendChild(newListItem);
                oppositeListAncestor.removeChild(listItem);
            }
            oppositeListAncestor.replaceWith(newList);
        } else {
            const currentList = getAncestor(range.startContainer, tag);
            if (currentList) {
                const fragment = document.createDocumentFragment();
                while (currentList.firstChild) {
                    const listItem = currentList.firstChild;
                    while (listItem.firstChild) {
                        fragment.appendChild(listItem.firstChild);
                    }
                    currentList.removeChild(listItem);
                }
                currentList.replaceWith(fragment);
            } else {
                const list = document.createElement(tag);
                const listItem = document.createElement('li');
                const clonedRange = range.cloneRange();
                const extractedContent = clonedRange.extractContents();
                listItem.appendChild(extractedContent);
                list.appendChild(listItem);
                range.deleteContents();
                range.insertNode(list);
            }
        }
    }, [getAncestor]);

    const checkFormattingAtCursor = useCallback(() => {
        const selection = window.getSelection();
        if (!selection.rangeCount) { return {}; }
        const range = selection.getRangeAt(0);
        let node = range.startContainer;

        if (node.nodeType === Node.TEXT_NODE) {
            node = node.parentNode;
        }

        const bold = isTagPresent(node, 'b') || isTagPresent(node, 'strong');
        const italic = isTagPresent(node, 'i') || isTagPresent(node, 'em');
        const underline = isTagPresent(node, 'u');
        const span = isTagPresent(node, 'span');
        if (span) {
            ref.current.innerHTML = node.innerHTML;
        }
        return { bold, italic, underline };
    }, []);

    const isTagPresent = useCallback((node, tag) => {
        while (node) {
            if (node.tagName && node.tagName.toLowerCase() === tag) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }, []);

    const removeTag = useCallback((node, tag) => {
        const tagToRemove = tag.toLowerCase();
        if (node.nodeType === Node.TEXT_NODE) {
            return node;
        }
        if (node.tagName && node.tagName.toLowerCase() === tagToRemove) {
            const fragment = document.createDocumentFragment();
            while (node.firstChild) {
                fragment.appendChild(node.firstChild);
            }
            return fragment;
        } else {
            const clone = node.cloneNode(false);
            while (node.firstChild) {
                clone.appendChild(removeTag(node.firstChild, tag));
            }
            return clone;
        }
    }, []);

    const toggleInlineStyle = (range, tag) => {
        const selection = window.getSelection();
        const selectedText = selection.toString();
        if (!selectedText) { return; }

        if (tag === 'ol' || tag === 'ul') {
            handleListFormatting(range, tag);
        } else {
            if (isTagPresent(range.startContainer, tag)) {
                const ancestor = getAncestor(range.startContainer, tag);
                if (ancestor) {
                    const fragment = removeTag(ancestor, tag);
                    ancestor.replaceWith(fragment);
                }
            } else {
                const span = document.createElement(tag);
                span.appendChild(range.extractContents());
                range.insertNode(span);
            }
        }
        selection.removeAllRanges();
        updateElement();
    };





    useEffect(() => {
        if (formatting.isFormattingChanged) {
            requestAnimationFrame(() => {
                const selection = window.getSelection();
                if (!selection.rangeCount) { return; }
                const range = selection.getRangeAt(0);

                console.log('previousFormatting.current', previousFormatting.current, formatting);

                if (previousFormatting.current.bold !== formatting.bold) {
                    previousFormatting.current.bold = formatting.bold;
                    toggleInlineStyle(range, 'b');
                }
                if (previousFormatting.current.italic !== formatting.italic) {
                    previousFormatting.current.italic = formatting.italic;
                    toggleInlineStyle(range, 'i');
                }
                if (previousFormatting.current.underline !== formatting.underline) {
                    previousFormatting.current.underline = formatting.underline;
                    toggleInlineStyle(range, 'u');
                }
                if (previousFormatting.current.orderedList !== formatting.orderedList) {
                    previousFormatting.current.orderedList = formatting.orderedList;
                    toggleInlineStyle(range, 'ol');
                }
                if (previousFormatting.current.unorderedList !== formatting.unorderedList) {
                    previousFormatting.current.unorderedList = formatting.unorderedList;
                    toggleInlineStyle(range, 'ul');
                }
            });
        }
    }, [formatting, toggleInlineStyle]);

    const handleContentChange = useCallback(
        _.debounce((e) => {
            updateElement(e.target.innerHTML);
        }, 500),
        [updateElement]
    );

    const handleSelectionChange = useCallback(() => {
        requestAnimationFrame(() => {
            const formattingAtCursor = checkFormattingAtCursor();
            previousFormatting.current.bold = formattingAtCursor.bold;
            previousFormatting.current.italic = formattingAtCursor.italic;
            previousFormatting.current.underline = formattingAtCursor.underline;
            setFormatting((prevFormatting) => ({
                ...prevFormatting,
                ...formattingAtCursor,
                isFormattingChanged: false,
            }));
        });
    }, [checkFormattingAtCursor, setFormatting]);

    useEffect(() => {
        document.addEventListener('selectionchange', handleSelectionChange);
        document.addEventListener('keyup', handleSelectionChange);

        return () => {
            document.removeEventListener('selectionchange', handleSelectionChange);
            document.removeEventListener('keyup', handleSelectionChange);
        };
    }, [handleSelectionChange]);

    return (
        <div className='rsw-editor' style={{ padding: '10px', cursor: 'move' }}>
            <div
                className='rsw-ce'
                contentEditable
                placeholder={props.placeholder}
                ref={ref}
                dangerouslySetInnerHTML={{ __html: propValue }}
                onInput={handleContentChange}
                style={{
                    ...props.style,
                    minHeight: '35px',
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                }}
            ></div>
        </div>
    );
});
SimpleWysiwyg.displayName = 'SimpleWysiwyg';

export default SimpleWysiwyg;
