import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { elementChanged } from 'redux-layout/actions';
import ResizableDraggable from '../Components/Editor/ResizableDragable';
import { MultilineEdit } from '../Components/Editor/MultiLineEdit';
import { ALL, CANVAS_EL, COMPLEX_TEXT, DIV, FRAME, IMG, INDIV, MENU_ID, POS, RND, SVG, TEXT } from './config';
import { useContextMenu } from 'react-contexify';
import { Rnd } from '../Components/Rnd';
import $ from 'jquery';
import _ from 'lodash';
import { Box, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { StudioContext } from '..';
import { PHOTOS } from '../LeftAddElements/config';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { FramePlaceholder } from 'config/svgs';
import { ResizeHandles } from '../Components/Rnd/ResizeHandles';
import TextElement from './TextElement';
import IframeRND from '../Components/Editor/IframeRND';

const styleRnd = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const CanvasElements = ({ el: getElement, index, style, lcanvasRef }) => {
  const dispatch = useDispatch();
  const { selectedElement } = useSelector((state) => state.Canvas.present);
  const [el, setEl] = React.useState({ ...getElement });

  useEffect(() => {
    if (getElement) {
      setEl({ ...getElement });
    }
  }, [getElement]);

  const { show } = useContextMenu({
    id: MENU_ID,
    props: {
      key: '',
    },
  });

  const getRotateValueFromString = (elemIdRnd) => {
    return elemIdRnd.style.transformRotate !== '' && elemIdRnd.style.transformRotate !== undefined
      ? typeof elemIdRnd.style.transformRotate === 'string'
        ? elemIdRnd.style.transformRotate?.split('(')[1]?.split('d')[0]
        : 0
      : '0';
  };

  const handleContextMenu = (event, element) => {
    event.preventDefault();
    dispatch(elementChanged(element));
    show({
      event,
      props: {
        key: element,
        element: element,
      },
    });
  };
  const elemDragStop = (e, d, elem) => {
    if (!elem.position) {
      return;
    }
    let el = $(CANVAS_EL);
    let elpos = {
      top: (d.y * 100) / el.height(),
      left: (d.x * 100) / el.width(),
    };
    let element = _.cloneDeep(elem);
    element.position.x = d.x;
    element.position.y = d.y;
    element.position.top = elpos.top;
    element.position.left = elpos.left;
    setEl((prev) => ({
      ...prev,
      position: {
        ...prev.position,
        x: d.x,
        y: d.y,
        top: elpos.top,
        left: elpos.left,
      },
    }));
    dispatch(elementChanged(element));
  };
  const resizeElements = (ref, el, position, direction, delta, e) => {
    let ele = $(CANVAS_EL);
    let elpos = {
      top: (position.y * 100) / ele.height(),
      left: (position.x * 100) / ele.width(),
    };
    if (el.type === TEXT || el.type === COMPLEX_TEXT) {
      let element = _.cloneDeep(el);
      let elWidth = ((ref.current.width + 24) / ele.width()) * 100;
      let elHeight = ((ref.current.height + 24) / ele.height()) * 100;
      element.position.width = elWidth;
      element.position.height = elHeight;
      element.position.top = elpos.top;
      element.position.left = elpos.left;
      element.style['font-size'] = ref.current.finalFontSize;
      dispatch(elementChanged(element));
      setEl((prev) => ({
        ...prev,
        position: {
          ...prev.position,
          width: elWidth,
          height: elHeight,
          top: elpos.top,
          left: elpos.left,
        },
        style: {
          ...prev.style,
          'font-size': ref.current.finalFontSize
        }
      }));
    } else {
      let element = _.cloneDeep(el);
      element.position.width = ref.style?.width;
      element.position.height = ref.style?.height;
      element.position.top = elpos.top;
      element.position.left = elpos.left;
      dispatch(elementChanged(element));
      setEl((prev) => ({
        ...prev,
        position: {
          ...prev.position,
          width: ref.style?.width,
          height: ref.style?.height,
          top: elpos.top,
          left: elpos.left,
        },
      }));
    }
  };
  const commonRNDProps = useCallback(
    (type) => {
      return {
        rotate: getRotateValueFromString(el),
        className: el.id === selectedElement?.id ? 'dragResizingActive' : '',
        onContextMenu: (e) => handleContextMenu(e, el),
        // onKeyDown: (e) => handleFocus(e, el),
        onDragStop: (e, d) => {
          elemDragStop(e, d, { ...el });
        },
        onResizeStop: (e, direction, ref, delta, position) => {
          resizeElements(ref, { ...el }, position, direction, delta, e);
        },
        size: {
          width: parseFloat(el.position.width) + '%',
          height: parseFloat(el.position.height) + '%',
        },
        position: getRNDPOS(el),
        bounds: '#lcanvas',
        style: getElemStyle(el, index, type),
      };
    },
    [el, index, selectedElement]
  );

  const getRNDPOS = useCallback(
    (el) => {
      if (style?.width && style?.height) {
        let x = (el.position.left * style.width.replace('px', '')) / 100;
        let y = (el.position.top * style?.height.replace('px', '')) / 100;
        return { x: x || 0, y: y || 0 };
      } else {
        return {
          x: 0,
          y: 0,
        };
      }
    },
    [style, el, commonRNDProps]
  );

  const getElemStyle = (elem, index, type = 'all', isRotate = true, canvasStyle = null) => {
    const el = { ...elem, style: { ...elem.style } };
    if (el.style['transformSkew'] === undefined) {
      el.style['transformSkew'] = '';
    }
    if (!el.style['transformRotate']) {
      el.style['transformRotate'] = '';
    }
    let style = getRecaliberatedStyleObject({ el, index, isRotate, canvasStyle });
    if (el.type === SVG) {
      style = {
        ...style,
        stroke: el.style['stroke'] ? el.style['stroke'] : '#000',
        fill: el.style['fill'] ? el.style['fill'] : '#000',
        strokeWidth: el.style['strokeWidth'] ? el.style['strokeWidth'] : 0.1,
      };
    }
    if (el.type === TEXT) {
      style = {
        ...style,
        padding: '4px 8px',
        overflowWrap: 'break-word',
        overflow: 'hidden',
      };
    }
    return getStyleAccordingToType({ el, index, style, type });
  };

  const handleMouseDown = (e, el) => {
    dispatch(elementChanged(el));
  };

  let addNewStyle;

  switch (el.type) {
    case TEXT:
      addNewStyle = {
        ...commonRNDProps(POS),
        style: {
          ...commonRNDProps(POS).style,
          backgroundColor: el.style['backgroundColor'] ? el.style['backgroundColor'] : 'rgba(255,255,255,0)',
        },
      };
      return (
        <TextElement
          handleMouseDown={handleMouseDown}
          el={el}
          selectedElement={selectedElement}
          handleContextMenu={handleContextMenu}
          getElemStyle={getElemStyle}
          index={index}
          style={style}
          addNewStyle={addNewStyle}
          lcanvasRef={lcanvasRef}
        />
      );
    case COMPLEX_TEXT:
      addNewStyle = {
        ...commonRNDProps(POS),
        style: {
          ...commonRNDProps(POS).style,
          backgroundColor: el.style['backgroundColor'] ? el.style['backgroundColor'] : 'rgba(255,255,255,0)',
        },
      };
      return (
        <TextElement
          handleMouseDown={handleMouseDown}
          el={el}
          selectedElement={selectedElement}
          handleContextMenu={handleContextMenu}
          getElemStyle={getElemStyle}
          index={index}
          style={style}
          addNewStyle={addNewStyle}
          lcanvasRef={lcanvasRef}
        />
      );
    case SVG:
      return (
        <Rnd {...commonRNDProps(RND)}>
          <svg
            id={el.id}
            onClick={() => dispatch(elementChanged(el))}
            style={{ ...getElemStyle(el, index, 'indiv', false), borderRadius: '1rem' }}
            {...el['attributes']}>
            {Array.isArray(el.childrens) &&
              el.childrens.map((item, index) => {
                const fillRule = item['fill-rule'];
                delete item['fill-rule'];
                return <path key={index} fillRule={fillRule} {...item}></path>;
              })}
          </svg>
        </Rnd>
      );
    case FRAME:
      return (
        <Rnd {...commonRNDProps(RND)}>
          <svg
            id={el.id}
            onClick={() => dispatch(elementChanged(el))}
            style={{
              ...getElemStyle(el, index, 'indiv', false),
              borderRadius: '1rem',
              fill: `url(#pattern${el.id})`,
            }}
            {...el['attributes']}>
            {el.childrens.map((item, index) => {
              const fillRule = item['fill-rule'];
              delete item['fill-rule'];
              return <path key={index} fillRule={fillRule} {...item} fill={`url(#pattern${el.id})`}></path>;
            })}
            {el?.content?.src ? (
              <defs>
                <pattern
                  id={`pattern${el.id}`}
                  patternUnits="userSpaceOnUse"
                  width={getElemStyle(el, index, 'indiv', false).width}
                  height={getElemStyle(el, index, 'indiv', false).height}>
                  <image
                    style={{ objectFit: 'cover' }}
                    href={el.content.src}
                    x={commonRNDProps(RND).x}
                    y={commonRNDProps(RND).y}
                    width={getElemStyle(el, index, 'indiv', false).width}
                    height={getElemStyle(el, index, 'indiv', false).height}
                  />
                </pattern>
              </defs>
            ) : (
              <FramePlaceholder id={`pattern${el.id}`} />
            )}
          </svg>
        </Rnd>
      );
    case IMG:
      if (el.content.src) {
        return (
          <Rnd {...commonRNDProps(POS)}>
            <img
              alt={el.name}
              onClick={() => dispatch(elementChanged(el, 'img'))}
              id={el.id}
              style={{ ...getElemStyle(el, index, 'indiv', false), borderRadius: '1rem' }}
              src={el.content?.src}
            />
          </Rnd>
        );
      } else {
        const { x, y } = getRNDPOS(el);
        return (
          <div
            className="image-placeholder"
            style={{
              ...getElemStyle(el, index, 'all'),
              transform: `translate(${x}px, ${y}px)`,
              width: parseFloat(el.position.width) + '%',
              height: parseFloat(el.position.height) + '%',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
            onContextMenu={(e) => handleContextMenu(e, el)}>
            <img
              alt={el.name}
              onClick={() => dispatch(elementChanged(el, 'img'))}
              id={el.id}
              style={{ ...getElemStyle(el, index, 'indiv', false), borderRadius: '1rem' }}
              src={el.content.placeholder}
            />
          </div>
        );
      }

    case DIV:
      //this is for embeds iframe
      const getStyle = {
        ...el.attributes,
        style: { border: 0 },
      };
      addNewStyle = {
        ...commonRNDProps(POS),
        style: {
          ...commonRNDProps(POS).style,
          backgroundColor: el.style['backgroundColor'] ? el.style['backgroundColor'] : 'rgba(255,255,255,0)',
        },
      };
      return (
        <IframeRND
          key={index}
          {...addNewStyle}
          id={el.id}
          onMouseDownEvent={(e) => handleMouseDown(e, el)}
          isVisible={el.id === selectedElement?.id}
          handleContextMenu={handleContextMenu}
          element={el}
          disableToolbar={true}
        >
          <div className="iframe-wrapper" id={el.id} style={{ width: '100%', height: '100%' }}>
            <iframe
              title="iframe"
              className={el.id === selectedElement?.id ? 'dragResizingActive' : ''}
              {...getStyle}
            />
          </div>
        </IframeRND>
      );

    default:
      return null;
  }
};

export default CanvasElements;

function getRecaliberatedStyleObject({ el, index, isRotate = true, canvasStyle }) {
  return {
    position: 'absolute',
    opacity: el.style['opacity'] ? el.style['opacity'] : '0.1',
    pointerEvents: el.style['pointer-events'] ? el.style['pointer-events'] : 'stroke',
    backgroundColor: el.style['background-color']
      ? el.style['background-color']
      : el.style['backgroundColor']
        ? el.style['backgroundColor']
        : 'rgba(255,255,255,0)',
    borderWidth: el.style['border-width'] ? el.style['border-width'] + 'px' : '0',
    borderStyle: el.style['border-style'] ? el.style['border-style'] : 'solid',
    display: el.style['display'] ? el.style['display'] : 'block',
    borderColor: el.style['border-color'] ? el.style['border-color'] : '#fff',
    borderRadius: el.style['border-radius'] ? el.style['border-radius'] + 'px' : '0',
    color: el.style['color'] ? el.style['color'] : '#000',
    fontFamily: el.style['font-family'] ? el.style['font-family'] : '',
    fontSize: el.style['font-size'] ? calcFontSize(el, canvasStyle) + 'px' : '55px',
    fontWeight: el.style['font-weight'] ? el.style['font-weight'] : '',
    textAlign: el.style['text-align'] ? el.style['text-align'] : 'center',
    zIndex: el.style['z-index'] ? el.style['z-index'] : index,
    fontStyle: el.style['font-style'] ? el.style['font-style'] : 'normal',
    textDecoration: el.style['text-decoration'] ? el.style['text-decoration'] : '',
    transform: el.style['transformRotate'] + ' ' + el.style['transformSkew'],
    textTransform: el.style['text-transform'] ? el.style['text-transform'] : '',
    verticalAlign: el.style['vertical1'] ? el.style['vertical1'] : '',
    lineHeight: el.style['line-height'] ? el.style['line-height'] : '1.5',
  };
}

function calcFontSize(el, canvasStyle) {
  let size = 0;
  let constant = 1000;
  let lcanvas = $('#lcanvas');
  if (el.textstyle) {
    size = el.textstyle['font-size'];
  } else {
    size = el.style['font-size'];
  }

  if (!size) {
    return false;
  }

  let fs;
  let sum;
  let con;
  if (canvasStyle?.width) {
    if (canvasStyle.width.includes('px')) {
      sum = parseInt(canvasStyle.width.replace('px', ''));
    } else if (canvasStyle.width.includes('%')) {
      sum = parseInt(canvasStyle.width.replace('%', ''));
    } else {
      sum = canvasStyle.width;
    }
  } else {
    sum = lcanvas.width();
  }

  let per = (sum / constant) * 100;
  fs = (per / 100) * size;
  if (per < 100) {
    fs -= parseInt(100 - per + 1) * 0.015;
  }
  return fs || '55';
}

function getStyleAccordingToType({ el, index, style, type = ALL }) {
  switch (type) {
    case ALL:
      return {
        ...style,
        top: el.position.top + '%',
        left: el.position.left + '%',
        width: el.position.width + '%',
        height: el.position.height + '%',
      };
    case INDIV:
      return {
        ...style,
        top: '0%',
        left: '0%',
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      };

    case POS:
      return {
        ...styleRnd,
        transform: el.style['transformRotate'] + ' ' + el.style['transformSkew'],
        zIndex: el.style['z-index'] ? el.style['z-index'] : index,
      };
    case RND:
      return {
        ...styleRnd,
        transform: el.style['transformRotate'] + ' ' + el.style['transformSkew'],
        zIndex: el.style['z-index'] ? el.style['z-index'] : index,
        x: el.position.x,
        y: el.position.y,
      };
    case TEXT:
      return {
        ...style,
        pointerEvents: null,
      };
  }
}
