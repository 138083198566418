import { useDrag, useDrop } from 'react-dnd';
import React, { useState, useRef, useContext } from 'react';
import SlidePreview from '../Components/SlidePreview';
import { SLIDE_TYPES } from 'config/constants';
import { MoreButton, SlideCardContainer } from './styles';
import { ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddSlide from './AddSlide';
import { Modal } from 'components/shared';
import { slideadded, slideDeleted } from 'redux-layout/actions';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AiIcon } from 'config/svgs';
import appConfig from 'config';
import { newID } from 'helpers/studioApi';
import { useDispatch, useSelector } from 'react-redux';
import { StudioContext } from '..';

const DraggableSlide = ({ slide, index, moveSlide, selectedSlide, handleSlideChange }) => {
    const ref = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const dispatch = useDispatch();
    const { slides } = useSelector((state) => state.Canvas.present);
    const { setElementType } = useContext(StudioContext);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget); // Set the anchorEl to the clicked MoreButton
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAdd = (index) => {
        let newSlide = appConfig.slideColor;
        dispatch(slideadded({ ...newSlide, id: newID('slide') }, index));
        handleMenuClose();
    };

    const handleDuplicate = (index) => {
        dispatch(slideadded({ ...selectedSlide, id: newID('slide') }, index));
        handleMenuClose();
    };

    const handleRecreateAi = () => {
        setElementType('TOOLBAR_REGENERATESLIDE');
        handleMenuClose();
    };

    const handleDelete = () => {
        if (slides.length === 1) {
            return;
        }
        dispatch(slideDeleted());
        handleMenuClose();
    };


    // Drag logic
    const [{ isDragging }, drag] = useDrag({
        type: SLIDE_TYPES.SLIDE,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    // Drop logic
    const [, drop] = useDrop({
        accept: SLIDE_TYPES.SLIDE,
        hover: (item, monitor) => {
            if (!ref.current) { return; }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) { return; }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Only move when the mouse has crossed half of the items height
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) { return; }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) { return; }

            // Perform the move
            moveSlide(dragIndex, hoverIndex);

            // Note: This is to prevent any flickering
            item.index = hoverIndex;
        },
    });

    drag(drop(ref));

    return (
        <Stack key={index} direction="column" spacing={2} ref={ref}>
            <SlideCardContainer
                isselected={selectedSlide.id === slide.id ? true : undefined}
                onClick={() => handleSlideChange(slide)}>
                <SlidePreview slide={slide} />
                {selectedSlide.id === slide.id && (
                    <MoreButton type="slide" onClick={handleMenuClick}>
                        <MoreHorizIcon />
                    </MoreButton>
                )}

            </SlideCardContainer>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom', // Position the menu relative to the top of the anchor element
                    horizontal: 'right', // Position the menu relative to the right of the anchor element
                }}
                transformOrigin={{
                    vertical: 'bottom', // Align the top of the menu with the top of the anchor element
                    horizontal: 'left', // Align the right side of the menu with the right side of the anchor element
                }}>
                <MenuItem onClick={() => handleAdd(index)}>
                    <ListItemIcon>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText>Add page</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleDuplicate(index)}>
                    <ListItemIcon>
                        <ContentCopyIcon />
                    </ListItemIcon>
                    <ListItemText>Duplicate page</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                        <DeleteOutlineIcon />
                    </ListItemIcon>
                    <ListItemText>Delete page</ListItemText>
                </MenuItem>
                {/* <MenuItem onClick={handleRecreateAi}>
                    <ListItemIcon>
                        <AiIcon />
                    </ListItemIcon>
                    <ListItemText>Recreate with AI</ListItemText>
                </MenuItem> */}
            </Menu>
        </Stack>
    );
};

export default DraggableSlide;
