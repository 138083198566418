import http from './http.service';

export const fetchData = async (url) => {
  try {
    const response = await http.get(url);
    return { data: response.data, count: response?.meta?.pagination?.total || 0 };
  } catch (error) {
    return { error };
  }
};

export const fetchCount = async (url) => {
  try {
    const { entity } = await http.get(url);
    return { entity };
  } catch (error) {
    return { error };
  }
};

export const createData = async (url, values) => {
  try {
    const { data } = await http.post(url, { data: values });
    return { data };
  } catch (error) {
    return { error };
  }
};

export const updateData = async (url, values) => {
  try {
    const { data } = await http.put(url, { data: values });
    return { data };
  } catch (error) {
    return { error };
  }
};

export const deleteData = async (url) => {
  try {
    const { data } = await http.delete(url);
    return { data };
  } catch (error) {
    return { error };
  }
};

export const createDuplicateProject = async (projectId, payload) => {
  return http.post(`project/duplicate/${projectId}`, payload);
};

export const createLessonOverride = async (payload) => {
  return http.post(`capi/lessonOverride`, payload);
};
