import images from 'config/images';

export const ELEMENTS = 'ELEMENTS';
export const TEXT = 'TEXT';
export const PHOTOS = 'PHOTOS';
export const YOURUPLOADS = 'YOURUPLOADS';
export const VIDEOS = 'VIDEOS';
export const AUDIO = 'AUDIO';
export const AUDIOUPLOADS = 'AUDIOUPLOADS';
export const EMBED = 'EMBED';
export const UPLOADS = 'UPLOADS';
export const BACKGROUND = 'BACKGROUND';
export const BACKGROUNDUPLOADS = 'BACKGROUNDUPLOADS';
export const SHAPES = 'SHAPES';
export const BUTTONS = 'BUTTONS';
export const ICONS = 'ICONS';
export const STICKERS = 'STICKERS';
export const GIFS = 'GIFS';
export const FRAMES = 'FRAMES';
export const TOOLBAR_RECREATEWITHAI = 'TOOLBAR_RECREATEWITHAI';
export const TOOLBAR_REGENERATESLIDE = 'TOOLBAR_REGENERATESLIDE';
export const TOOLBAR_ANIMATION = 'TOOLBAR_ANIMATION';
export const TOOLBAR_COLORDRAWER = 'TOOLBAR_COLORDRAWER';

export const PHOTO_COLLECTION = 'PHOTO_COLLECTION';


export const addElements = [
  {
    id: 1,
    name: 'Elements',
    icon: images.studio.addLeft.elements,
    route: ELEMENTS,
    children: [
      {
        id: 1,
        name: 'Shapes',
        icon: '',
        route: SHAPES,
      },
      {
        id: 2,
        name: 'Buttons',
        icon: '',
        route: BUTTONS,
      },
      {
        id: 3,
        name: 'Icons',
        icon: '',
        route: ICONS,
      },
      {
        id: 4,
        name: 'Stickers',
        icon: '',
        route: STICKERS,
      },
      {
        id: 5,
        name: 'GIFs',
        icon: '',
        route: GIFS,
      },
      {
        id: 6,
        name: 'Frames',
        icon: '',
        route: FRAMES,
      },
    ],
  },
  {
    id: 2,
    name: 'Text',
    icon: images.studio.addLeft.text,
    route: TEXT,
  },
  {
    id: 3,
    name: 'Photos',
    icon: images.studio.addLeft.image,
    route: PHOTOS,
    children: [
      {
        id: 1,
        name: 'YourUploads',
        icon: '',
        route: YOURUPLOADS,
      },
    ],
  },
  {
    id: 4,
    name: 'Videos',
    icon: images.studio.addLeft.video,
    route: VIDEOS,
  },
  {
    id: 5,
    name: 'Audio',
    icon: images.studio.addLeft.audio,
    route: AUDIO,
    children: [
      {
        id: 1,
        name: 'AudioUploads',
        icon: '',
        route: AUDIOUPLOADS,
      },
    ],
  },
  {
    id: 6,
    name: 'Embed',
    icon: images.studio.addLeft.code,
    route: EMBED,
  },
  {
    id: 7,
    name: 'Uploads',
    icon: images.studio.addLeft.upload,
    route: UPLOADS,
  },
  {
    id: 8,
    name: 'Background',
    icon: images.studio.addLeft.background,
    route: BACKGROUND,
    children: [
      {
        id: 1,
        name: 'BackgroundUploads',
        icon: '',
        route: BACKGROUNDUPLOADS,
      },
    ],
  },
  {
    id: 9,
    name: 'toolbar_recreatewithai',
    icon: '',
    route: TOOLBAR_RECREATEWITHAI,
  },
  {
    id: 10,
    name: 'toolbar_regenerateslide',
    icon: '',
    route: TOOLBAR_REGENERATESLIDE,
  },
  {
    id: 11,
    name: 'toolbar_animation',
    icon: '',
    route: TOOLBAR_ANIMATION,
  },
  {
    id: 12,
    name: 'toolbar_colordrawer',
    icon: '',
    route: TOOLBAR_COLORDRAWER,
  },
];
